<template>
  <v-menu
    bottom
    close-delay="100"
    content-class="rounded"
    left
    max-height="500"
    offset-y
    open-delay="60"
    open-on-hover
    transition="slide-y-transition"
    v-bind="$attrs"
    v-on="$listeners"
    v-if="items.length > 0"
  >
    <template #activator="props">
      <slot name="activator" v-bind="{ ...props }" />
    </template>

    <app-sheet :outlined="false">
      <slot v-if="$slots.default" />

      <menu-list v-else :items="items">
        <template v-if="$scopedSlots.item" #item="props">
          <slot name="item" v-bind="{ ...props }" />
        </template>
      </menu-list>
    </app-sheet>
  </v-menu>
</template>

<script>
// Components
export default {
  name: "AppMenu",
  components: {
    AppSheet: () => import("@/components/core/app/Sheet"),
    MenuList: () => import("@/components/core/menu/List"),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>